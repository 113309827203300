import React, { useState } from "react"
import SEO from "../components/seo"
import Footer from "../components/footer/footer.component"
import Header from "../components/header/header.component"
import "../styles/styles.css"
import { FormControl } from "react-bootstrap"

const ContactForm = () => {
  const [form, setForm] = useState({})

  const handleChange = event => {
    const name = event.target.name
    const value = event.target.value
    setForm(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = event => {
    event.preventDefault()
    window.location.href = `https://wa.me/628118336243/?text=${form.message}%0A%0Adari%20${form.name}%0Avia%20nibrasplay.co.id`
  }

  return (
    <div>
      <SEO title="Contact" url="https://nibrasplay.co.id/contact" />
      <Header />
      <div className="container-contact">
        <h1>Kontak Kami</h1>
        <form method="post" id="form" onSubmit={handleSubmit} class="topBefore">
          <input
            id="name"
            type="text"
            name="name"
            placeholder="Nama"
            value={form.name || ""}
            onChange={handleChange}
          />
          {/* <input 
            id="name" 
            type="email" 
            name="email"
            placeholder="Email"
            value={form.email || ""} 
            onChange={handleChange}
          /> */}
          <textarea
            id="message"
            type="text"
            name="message"
            placeholder="Pesan"
            value={form.message || ""}
            onChange={handleChange}
          />
          <input id="submit" type="submit" className="btn" value="Kirim" />
        </form>
      </div>
      <Footer />
    </div>
  )
}

export default ContactForm
